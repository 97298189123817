import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { Button, Divider, RichText, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import CareersSection from "./_careersSection"
import CallOutSection from "./_callOutSection"
import FullImageSection from "./_fullImageSection"
import BenefitsSection from "./_benefitsSection"
import GallerySection from "./_gallerySection"

function CareersPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicCareers {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          hero_image {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          hero_title {
            text
          }
          hero_description {
            html
          }
          change_image {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          change_secondary_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          change_description {
            html
          }
          change_button_text
          change_button_link {
            slug
            type
            url
          }
          benefits_title {
            text
          }
          benefits {
            benefit
          }
          benefits_gallery {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
          }
          careers {
            title {
              text
            }
            description {
              html
            }
            link {
              url
            }
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })

  return (
    <>
      <SEO
        title={
          finalData.prismicCareers.data.seo_title &&
          finalData.prismicCareers.data.seo_title.text
        }
        description={
          finalData.prismicCareers.data.seo_description &&
          finalData.prismicCareers.data.seo_description.text
        }
        image={
          finalData.prismicCareers.data.seo_image &&
          finalData.prismicCareers.data.seo_image.url
        }
        imageAlt={
          finalData.prismicCareers.data.seo_image &&
          finalData.prismicCareers.data.seo_image.alt
        }
      />

      {/* ============ INTRO ============ */}
      <IntroSection
        id="careers-intro"
        data={{
          image:
            finalData.prismicCareers.data.hero_image &&
            finalData.prismicCareers.data.hero_image.fluid &&
            finalData.prismicCareers.data.hero_image,
          title:
            finalData.prismicCareers.data.hero_title &&
            finalData.prismicCareers.data.hero_title.text,
          bodyContent:
            finalData.prismicCareers.data.hero_description &&
            finalData.prismicCareers.data.hero_description.html,
        }}
      />

      {/* ============ FULL IMAGE ============ */}
      <FullImageSection
        id="careers-fullImage"
        data={{
          image:
            finalData.prismicCareers.data.change_image &&
            finalData.prismicCareers.data.change_image.fluid &&
            finalData.prismicCareers.data.change_image,
        }}
      />

      {/* ============ CALL OUT ============ */}
      <CallOutSection
        id="careers-callOut"
        data={{
          image:
            finalData.prismicCareers.data.change_secondary_image &&
            finalData.prismicCareers.data.change_secondary_image.fluid &&
            finalData.prismicCareers.data.change_secondary_image,
          bodyContent:
            finalData.prismicCareers.data.change_description &&
            finalData.prismicCareers.data.change_description.html,
          buttonLabel: finalData.prismicCareers.data.change_button_text,
          buttonTo: finalData.prismicCareers.data.change_button_link,
        }}
      />

      <Divider />

      {/* ============ BENEFITS ============ */}
      <BenefitsSection
        id="careers-benefits"
        data={{
          title:
            finalData.prismicCareers.data.benefits_title &&
            finalData.prismicCareers.data.benefits_title.text,
          benefits:
            finalData.prismicCareers.data.benefits &&
            finalData.prismicCareers.data.benefits,
        }}
      />

      {/* ============ GALLERY ============ */}
      <GallerySection
        id="careers-gallery"
        data={{
          images: finalData.prismicCareers.data.benefits_gallery.map(
            ({ image }) => ({
              image: image,
            })
          ),
        }}
      />

      {/* ============ CAREERS ============ */}
      <CareersSection
        id="careers-careers"
        data={{
          title: "Current Openings",
          careers: finalData.prismicCareers.data.careers.map(
            ({ title, description, link }) => ({
              heading: title && title.text,
              children: (
                <>
                  <RichText
                    content={{
                      html: description && description.html,
                    }}
                  />
                  {link && link.url && (
                    <Button
                      children={`Apply now`}
                      as="a"
                      href={link.url}
                      rel="nopener noreferrer"
                      target="_blank"
                      intent="primary"
                      appearance="primary"
                      mt="layout.4"
                    />
                  )}
                </>
              ),
            })
          ),
        }}
      />
    </>
  )
}

export default CareersPage
