import React from "react"
import Image from "gatsby-image/withIEPolyfill"

import { AspectRatio, Wrapper } from "components"

const FullImageSection = ({ data, id }) => {
  return (
    <>
      {data.image && (
        <Wrapper id={id} maxWidth={null} px={null} pt={0}>
          <AspectRatio ratio="16:9" bg="wash">
            <Image
              fluid={data.image && data.image.fluid}
              alt={data.image && data.image.alt}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              objectFit="cover"
              objectPosition="center"
            />
          </AspectRatio>
        </Wrapper>
      )}
    </>
  )
}

export default FullImageSection
