import React from "react"

// Project components
import { AccordionGroup, Heading, Wrapper } from "components"

const CareersSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has careers set */}
      {data.careers && data.careers.length > 0 && data.title && (
        <Wrapper id={id}>
          {data.title && (
            <Heading size={800} mb="layout.4">
              {data.title}
            </Heading>
          )}
          <AccordionGroup items={data.careers} />
        </Wrapper>
      )}
      {/* We only render the section if it has no careers set */}
      {!data.careers && (
        <Wrapper id={id}>
          <Heading size={700}>
            No postings right now! Check back later or feel free to hand in your
            resume in person.
          </Heading>
        </Wrapper>
      )}
    </>
  )
}

export default CareersSection
