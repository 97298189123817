import React from "react"
import css from "@styled-system/css"

import { Box, Flex, Heading, Wrapper } from "components"

const BenefitPill = ({ title }) => (
  <Box data-component-id="benefitPill">
    <Flex
      alignItems="center"
      height={[42, null, null, 64]}
      p={["spacing.2", null, null, "spacing.3"]}
      borderRadius={9999}
      bg="wash"
    >
      <Heading
        size={800}
        mx={["spacing.3", null, null, "spacing.4"]}
        pb="spacing.2"
        color="brand.primary"
      >
        {title}
      </Heading>
    </Flex>
  </Box>
)

const BenefitsSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.benefits) && (
        <Wrapper id={id}>
          <Heading as="div" size={900} fontWeight="normal" textAlign="center">
            {data.title && (
              <Box
                as="p"
                m={0}
                pb={data.benefits && ["layout.3", null, null, "layout.4"]}
              >
                {data.title}
              </Box>
            )}

            {/* Services split */}
            {data.benefits && (
              <Flex
                justifyContent="center"
                flexWrap="wrap"
                m={["-2px", "-4px", null, "-8px"]}
                css={css({
                  "> *": {
                    p: ["2px", "4px", null, "8px"],
                  },
                })}
              >
                {data.benefits.map((benefit, index) => (
                  <BenefitPill
                    title={benefit.benefit}
                    key={"benefit" + index}
                  />
                ))}
              </Flex>
            )}
          </Heading>
        </Wrapper>
      )}
    </>
  )
}

export default BenefitsSection
